/**
 * Base Variables
 */
$font-header: 'Space Mono', monospace;
$font-body: 'Inconsolata', monospace;
$font-post: 'Lora', serif;

$color-alpha: #9f8f68;
$color-alpha--light: #f5f3ef;

$color-black: #0a0a0a;
$color-grey: #f8f9f9;
$color-white: #fff;

$mq-xs: 20em;
$mq-sm: 30em;
$mq-md: 54em;
$mq-lg: 64em;
$mq-xl: 76.5em;
$mq-xxl: 114em;

/**
 * Base Settings/Overwrite Normalize
 */
*, *:before, *:after {
  box-sizing: border-box;
}

body {
  font-family: $font-body;
  font-feature-settings: 'liga' 0;
  font-size: 100%;
  line-height: 1.6;

  @media (min-width: $mq-xs) {
    font-size: 102.5%;
  }

  @media (min-width: $mq-sm) {
    font-size: 105%;
  }

  @media (min-width: $mq-md) {
    font-size: 107.5%;
  }

  @media (min-width: $mq-lg) {
    font-size: 110%;
  }

  @media (min-width: $mq-xl) {
    font-size: 115%;
  }

  @media (min-width: $mq-xxl) {
    font-size: 125%;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;

  font-family: $font-header;
  line-height: 1.1;
}

h1 {
  $fontSize: 1.7em;

  @media (min-width: $mq-md) {
    font-size: $fontSize;
  }

  @media (min-width: $mq-xl) {
    font-size: $fontSize + 0.5em;
  }
}

h2 {
  margin-bottom: 0.25em;

  font-size: 1.75em;

  @media (min-width: $mq-md) {
    font-size: 2em;
  }
}

h3 {
  margin-bottom: 0.1em;

  font-size: 1.5em;
}

h4 {
  margin-bottom: 0.1em;

  font-size: 1.25em;
}

p {
  margin: 0 0 0.75em;
}

a {
  color: $color-black;
  text-decoration: none;
  transition: color 0.4s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    color: $color-alpha;
  }
}

figure {
  margin: 0;
}

img {
  display: block;
  width: 100%;
  max-width: 100%;
}

blockquote {
  margin: 2em 0;

  font-family: $font-header;
  font-weight: 700;
  font-size: 1.25em;
}

cite {
  display: block;
  margin-top: 0.125em;

  font-family: $font-body;
  font-weight: 400;
  font-style: normal;

  &:before {
    content: "\2014 \0020";
  }
}

ol, ul {
  margin: 0;
  padding: 0;
}

/**
 * Text Selection
 */
::selection {
  color: $color-white;
  background-color: $color-alpha;
  text-shadow: none;
}
