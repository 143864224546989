/**
 * Animations
 */
@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fade-in {
  will-change: opacity;
  animation: fade-in 2s both;
}
